import { render, staticRenderFns } from "./SearchResultTableCellCompany.vue?vue&type=template&id=55a63e60"
import script from "./SearchResultTableCellCompany.vue?vue&type=script&lang=js"
export * from "./SearchResultTableCellCompany.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompanyLink: require('/app/components/EntityLink/components/CompanyLink.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default})
