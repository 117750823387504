
import CompanyLink from "~/components/EntityLink/components/CompanyLink.vue";

export default {
  name: "SearchResultTableCellProperty",

  components: {
    CompanyLink,
  },

  inheritAttrs: false,

  props: {
    /**
     * cvr number
     */
    cvr: {
      type: [String, Number],
      required: true,
    },

    /**
     * company name
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * interface: [{key, value}]
     */
    keyFigures: {
      type: Object,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{key, value}]
     */
    keyFigureLabels: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
